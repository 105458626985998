<template>
  <ListPageContainer :title="$t('announce.news')">
    <r-row class="px-4" v-if="currentDataSet.length > 0 && !loading">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" type="news" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading"/>

    <ListLoading v-else/>
  </ListPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import NothingFound from "@/components/Miscellaneous/NothingFound";
import ListLoading from "@/components/Miscellaneous/ListLoading";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import LazyAnnouncement from "@/components/LazyAnnouncement/LazyAnnouncement";

export default {
  name: "NewsListView",

  components: {
    LazyAnnouncement,
    NothingFound,
    ListLoading,
    ListPageContainer,
  },

  computed: {
    ...mapGetters(['GET_NEWS',]),

    currentDataSet() {
      return this.GET_NEWS;
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    goToItem(item) {
      this.$router.push({ path: `/news/${item.id}` });
    },
  }
}
</script>
